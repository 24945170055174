<template>
  <div>
    <!-- 大屏首页 -->
    <section
      ref="refFlow"
      class="section p0 flow_box flex-w"
      :class="index < 5 ? 'panel' : ''"
      v-for="(item, index) in list"
      :key="index"
      style="padding-bottom: 0"
    >
      <div class="col-lg-9 col-9 p0 container_left flex-cc">
        <div class="flex-w info_box panelFlow">
          <div class="flex-c">
            <h1 class="number colorE7E7E7 T64px_Bold">
              {{ item.number }}
            </h1>
            <span class="title T48px_Regular color232020">
              {{ item.title }}
            </span>
            <span class="text T16px_Regular color232020">
              {{ item.tips }}
            </span>
          </div>
          <img class="info_image" :src="item.url" alt="" />
        </div>
      </div>
      <!-- <div class="col-lg-3  col-3 p0 container_right flex-cc">
        <span class="container_right_span T36px_Regular panel-text act">{{
          item.number
        }}</span>
      </div> -->
      <div class="col-lg-3  col-3 p0 container_right flex-cc">
        <span v-for="(items,indexs) in list" :key="indexs" :class="index == indexs ? 'act' : ''" class="container_right_span T36px_Regular panel-text ">{{
          items.number
        }}</span>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      list: [
        {
          title: "提前租房计划",
          tips: "提前三个月开始租房计划是确保租房过程顺利进行的关键步骤。首先，明确您的租房需求，包括房屋的大小、房型、设施要求、以及您期望的租金范围。同时，制定合理的预算，不仅要涵盖月租，还要考虑到押金、水电费、网络等附加费用",
          number: "01",
          url: require("../../assets/images/renting_guide/img1.png"),
        },
        {
          title: "租房资料准备",
          tips: "确保所有相关的租房文件准备齐全是顺利租房的重要一步。准备好收入证明，证明您是否有足够的经济能力支付租金。收入证明可以是最近几个月的工资单或银行流水。信用报告也是评估租客信用度的重要依据，良好的信用记录有助于提高租房成功率。最后，别忘了准备身份证明文件，如护照、身份证或驾照，这些都是租房过程中不可或缺的证明文件",
          number: "02",
          url: require("../../assets/images/renting_guide/img2.png"),
        },
        {
          title: "租房申请服务",
          tips: "提前三个月开始租房计划是确保租房过程顺利进行的关键步骤。首先，明确您的租房需求，包括房屋的大小、房型、设施要求、以及您期望的租金范围。同时，制定合理的预算，不仅要涵盖月租，还要考虑到押金、水电费、网络等附加费用",
          number: "03",
          url: require("../../assets/images/renting_guide/img3.png"),
        },
        {
          title: "谈判与签约",
          tips: "在确定理想房源后，我们将协助您进行租房谈判，确保您获得最合适的租金和条款。从租金、租期到其他附加条款的协商，我们将为您提供有力的支持，让您在签约时做到心中有数，避免任何不必要的风险和困扰。",
          number: "04",
          url: require("../../assets/images/renting_guide/img4.png"),
        },
        {
          title: "入住检查与维护",
          tips: "在您入住前，我们将安排专业人员进行全面的房屋检查，确保房屋没有任何损坏，所有设施设备齐全并正常运行，以便您能够安心入住。",
          number: "05",
          url: require("../../assets/images/renting_guide/img5.png"),
        },
        {
          title: "入住服务与权益",
          tips: "我们会为您提供全面的租后管理服务，处理突发问题及维修需求，确保您居住无忧。 了解您在租房期间的权益，确保租约中的条款得到执行。",
          number: "06",
          url: require("../../assets/images/renting_guide/img6.png"),
        },
      ],
    };
  },
  mounted() {
    this.slideInit();
  },

  methods: {
    infoRouter(id) {
      this.$router.push({
        path: "houseInfo",
        query: { id: id },
      });
    },
    /**
     * 滑动页面效果初始化
     */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.panel,.flow_box {
  background: #fff;
  max-height: 100vh;
}
.container_left {
  max-height: 100vh;
  // position: relative;
  // overflow: hidden;
  z-index: 2;
  background: #fff;
  // flex-wrap: nowrap;

  .info_box {
    width: 100%;
    position: relative;
    color: #232020;
    padding-left: 1.3438rem;
    // overflow: hidden;
    background: #fff;
    z-index: 1;
    .number {
      color: #e7e7e7;
      font-size: 3.25rem;
      position: absolute;
      top: -2rem;
      z-index: -1;
    }

    .title {
      height: 1.9063rem;
    }

    .text {
      width: 10.0313rem;
      line-height: 1.0625rem;
      margin-top: 1.0625rem;
    }
  }

  .info_image {
    width: 5.6875rem;
    height: 5.8125rem;
    position: absolute;
    right: -1.1875rem;
    z-index: 9;
  }
}

.container_right {
  min-height: 100vh;
  background: #232020;

  // position: relative;
  // z-index: 2;
  .container_right_span {
    width: 3.5313rem;
    height: 3.375rem;
    line-height: 3.375rem;
    font-weight: 500;
    text-align: center;
    font-family: "HarmonyOS Sans TC Bold";
    color: #342828;

    &.act {
      font-size: 1.5rem;
      color: #fff;
    }
  }
}
</style>
